@font-face {   
    font-family: 'GTAmerica-CompressedBlack'; 
    src: url('../assets/GT-America-Compressed-Black.woff2');
}


@font-face {   
    font-family: 'GTAmerica-Regular'; 
    src: url('../assets/GT-America-Standard-Regular.woff2');
}

@font-face {   
    font-family: 'GTAmericaMono-Regular'; 
    src: url('../assets/GT-America-Mono-Regular.woff2');
  }


h1{
    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "GTAmerica-CompressedBlack";
    font-size: 30px;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}

p, li{
    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "GTAmerica-Regular";
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 25px;
}

.mono{
    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "GTAmericaMono-Regular";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 20px;
}

.foryou{

    float:right;

    margin: auto;

    line-height: 1.1;
    /*width: 0%;*/
}

.headerLabel{
    opacity: 1;
    font-family: "GTAmericaMono-Regular";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 20px;
    color: rgba(255,255,255,1);
}

.headerLabel:hover{
    text-decoration: underline;
}

.tab-selected{
    text-decoration: underline;
}

.card-content-md p {
    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "GTAmerica-Regular";
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 25px;
    margin:0 ;
}

.card-content-md h1 {
    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "GTAmerica-CompressedBlack";
    font-size: 30px;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}

.barLogo{
    margin-right: auto;
}

.locTitle{
    opacity: 1;
color: rgba(255,255,255,1);
font-family: "GTAmerica-CompressedBlack";
font-size: 60px;
font-style: normal;
letter-spacing: 0px;
text-align: left;
line-height: 60px;
}

.sc-ion-textarea-md{
    

    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "GTAmericaMono-Regular";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;

}

.item-textarea{
    border: 1px solid rgba(255,255,255, 0.5);
}

.item-has-focus{
    border: 1px solid rgba(255,255,255, 1);
}

.item p{
    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "GTAmericaMono-Regular";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;

}

.footerLink{
    opacity: 1;
    color: rgba(255,255,255,1);
    font-family: "GTAmericaMono-Regular";
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 20px;
    margin-bottom: 0px;
    margin-top: 8px;
}


.footerLink a{
    word-wrap: normal;
}

.footer-md::before{
    background-image: none;
}



@media (min-width: 576px) { 
    ion-grid{
      width: 80%;
    }

    .homeLogo{
        width: 35vh;
        margin-left: auto;
        margin-right: auto;
    }

    .rightCard{
        vertical-align: middle;
    }

  }

  /*@media (max-width: 576px) { */
    .fContent{

        padding-bottom: 2rem;
    }

  /*}*/